
export default {
    props: ['keywords', 'searchFocus'],
    data() {
        return {
            searchLoading : false,
            searchResults : null,
            searchTimeoutHolder : null,
            searchMaxResults : 2,
            searchPagination : null,
            searchHeight: 0,
            timeoutHolder : null
        }
    },
    computed : {
        wH(){
            return this.$store.state.scroll.wH;
        },
        searchOpen : {
            get(){
                return this.$store.state.layout.searchOpen;
            },
            set(v){
                this.$store.commit("layout/setSearchOpen",v)
            }
        },
    },
    watch : {
        searchFocus(t){
            this.timeoutHolder = setTimeout(() => {
                this.searchOpen = t
            }, 500);
        },
        keywords(){
            clearTimeout(this.searchTimeoutHolder);
            this.searchTimeoutHolder = setTimeout(this.searchArgota,400);
        }
    },
    beforeDestroy() {
        if(this.searchTimeoutHolder) clearTimeout(this.searchTimeoutHolder);
        if(this.timeoutHolder) clearTimeout(this.timeoutHolder);
    },
    mounted(){
        this.calcSearchHeight()
    },
    methods: {
        calcSearchHeight(){
            if(this.$el){
                const top = parseInt(this.$el.getBoundingClientRect().top)
                this.searchHeight = this.wH - top;
            }
            window.requestAnimationFrame(this.calcSearchHeight);
        },
        searchArgota(){
            if(!this.keywords){
                this.error = false;
                this.searchLoading = false;
                this.searchResults = null;
            }else{
                this.error = false;
                this.searchLoading = true;
                var payload = JSON.parse(JSON.stringify(this.keywords));
                this.$axios.post('/products/search/new',{
                    pagination: {
                        page_size: this.searchMaxResults,
                        page: 1
                    },
                    ordering: {
                        order_by : "productName",
                        order_direction : "DESC"
                    },
                    search : payload,
                    filters : { }
                }).then(axRes => { 
                    this.searchResults = axRes.data.products;
                    this.searchPagination = axRes.data.pagination;
                    this.searchLoading = false;
                }).catch(axErr => {
                    this.error = true;
                    this.searchLoading = false;
                })
            }
        }
    },
}
